import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonPage,
  IonContent,
  IonButton,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
  IonButtons,
  IonIcon,
  IonSelect,
  IonList,
  IonItem,
  IonSelectOption,
} from '@ionic/react';
import { Plugins } from '@capacitor/core';
import api from '../../services/api-login';
import * as AuthActions from '../../store/modules/auth/actions';
import Divider from '../../components/Divider';
import Loading from '../../components/Loading';
import { TitleApp, Container } from '../../styles/shared';
import { add } from 'ionicons/icons';
import './style.css';
import enviroment from '../../constants/enviroment';

const { Storage } = Plugins;

const Login = ({ history, login }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState({ isShow: false, message: '' });
  const [inputLogin, setLogin] = useState('');
  const [selectEmpresa, setSelectEmpresa] = useState('');
  const [inputPassword, setPassword] = useState('');
  const [listaCnpj, setListaCnpj] = useState([]);
  const [storageListaCnpj, setstorageListaCnpj] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    Storage.get({ key: 'listaEmpresa' }).then(data => {
      const listaEmpresa = JSON.parse(data.value);
      setstorageListaCnpj(listaEmpresa);
      setListaCnpj(listaEmpresa.map(empresa => empresa.cnpj));
    });
    Storage.get({ key: 'empresaLicenciada' }).then(data => {
      const empresaLicenciada = JSON.parse(data.value);
      setSelectEmpresa(empresaLicenciada.cnpj);
    });
  }, []);

  const onChangeSelecionarEmpresa = cnpj => {
    const empresa = storageListaCnpj.find(storeCnpj => storeCnpj.cnpj === cnpj);
    setSelectEmpresa(empresa.cnpj);
    const empresaLicenciada = {
      cnpj: empresa.cnpj,
      razaoSocial: empresa.razaoSocial,
    };
    Storage.set({
      key: 'tokenLicenca',
      value: empresa.token,
    });
    Storage.set({
      key: 'empresaLicenciada',
      value: JSON.stringify(empresaLicenciada),
    });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (inputLogin.trim() === '' || inputPassword.trim() === '') {
      setToast({
        isShow: true,
        message: 'Usuário e senha devem ser preenchidos',
      });
      return;
    }
    setIsLoading(true);
    api
      .post('/login', {
        login: inputLogin,
        password: inputPassword,
      })
      .then(async response => {
        setIsLoading(false);
        if (response.data.error) {
          setError(response.data.error);
        } else {
          const { token, isService } = response.data;
          Promise.all([
            Storage.set({ key: 'usuarioToken', value: token }),
            Storage.set({ key: 'isService', value: isService }),
          ]).then(() => {
            login();
            history.push('/tabs/dashboard');
          });
        }
      })
      .catch(({ response }) => {
        setIsLoading(false);
        if (response.status === 401) {
          Storage.clear();
        }
        const { message } = response.data;
        setToast({ isShow: true, message });
      });
  }

  return (
    <>
      <IonPage id="login">
        <IonHeader>
          <IonToolbar color="primary">
            <TitleApp>
              SIAF <span>Controller</span>
            </TitleApp>
            <IonButtons slot="primary">
              <IonButton onClick={() => history.push('/add-empresa')}>
                <IonIcon slot="icon-only" icon={add} size="large"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid fixed>
            <IonRow>
              <IonCol offsetLg="4" sizeLg="4" size="12">
                <Container>
                  <IonImg
                    src={require('../../assets/imgs/logo-intro.png')}
                    alt="Início"
                  />
                </Container>
                <Divider />
                <Container>
                  <form onSubmit={e => handleSubmit(e)}>
                    {isLoading ? (
                      <Loading />
                    ) : (
                      <>
                        <p id="error">{error !== '' ? error : ''}</p>
                        <IonList>
                          <IonItem>
                            <IonSelect
                              required
                              value={selectEmpresa}
                              placeholder="Selecione a empresa"
                              onIonChange={e =>
                                onChangeSelecionarEmpresa(e.target.value)
                              }>
                              {listaCnpj.map(item => {
                                return (
                                  <IonSelectOption value={item}>{item}</IonSelectOption>
                                );
                              })}
                            </IonSelect>
                          </IonItem>
                        </IonList>
                        <div>
                          <p>Login</p>
                          <input
                            className={isLoading ? 'loginDisabled' : 'loginInput'}
                            disabled={isLoading}
                            required={true}
                            value={inputLogin}
                            onChange={e => setLogin(e.target.value)}
                          />
                        </div>
                        <div style={{ marginBottom: 20 }}>
                          <p>Senha</p>
                          <input
                            className={isLoading ? 'loginDisabled' : 'loginInput'}
                            disabled={isLoading}
                            required={true}
                            autocomplete="off"
                            type="password"
                            value={inputPassword}
                            onChange={e => setPassword(e.target.value)}
                          />
                        </div>
                      </>
                    )}
                    <IonButton
                      color="primary"
                      expand="block"
                      type="submit"
                      disabled={isLoading}>
                      Acessar
                    </IonButton>
                  </form>
                </Container>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <footer className="versao">Versão Siaf Controller - {enviroment.version}</footer>
      </IonPage>

      <IonToast
        isOpen={toast.isShow}
        onDidDismiss={() => setToast({ isShow: false })}
        message={toast.message}
        duration={1000}
      />
    </>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators(AuthActions, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Login));
